/* eslint-disable */
import HttpService from '../HttpService';
import Formatters from '@/shared/formatters/formatters.js'

export default class PlanChangeReasonServiceSyncBase {
  constructor() {
    this._httpService = new HttpService('/contract/plan_change_reason');
    this.formatter = new Formatters();
  }

  async FindAll(){
    let reasons = await this._httpService.get( '');
    reasons.data = reasons.data.map((reason) => ({
      ...reason,
      name: this.formatter.formatToTitleCase(reason.name),
      code: reason.code,
    }));
    return reasons;
  }
 };
