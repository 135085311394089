<template>
  <div>
    <v-row class="mt-10 pl-3 pr-7" justify="space-between">
      <div>
        <p class="title">Dados Bancários</p>
      </div>
      <div>
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" color="primary" v-else large @click="onClickSaveSessionStorage()">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>
    <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
      <v-row class="mt-10 pr-4">
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            v-model="bank.code"
            :items="banks"
            item-text="description"
            item-value="code"
            outlined
            label="Cód. do Banco"
            placeholder="Cod. do Banco"
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
            :disabled="isDisabledFields"
            clearable
            :class="verifyFieldInArray('bank') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="bank.agency"
            @keydown="$event.key === '-' ? $event.preventDefault() : null"
            @blur="bank.agency ? rule.verifyNegative(bank.agency, 'fieldCheckedBankAgency') : ''"
            @focus="verifyMessageBankAgency = rule.isFocusVerifyNegative(true, bank.agency)"
            @change="verifyMessageBankAgency = rule.isFocusVerifyNegative(false, bank.agency)"
            id="fieldCheckedBankAgency"
            v-mask="'#########'"
            :error-messages="verifyMessageBankAgency ? '*Preencha corretamente' : ''"
            label="Agência"
            placeholder="Informe apenas números"
            color="textPrimary"
            outlined
            :disabled="isDisabledFields"
            :rules="[rule.validateNegativeDecimal(bank.agency, 'int')]"
            :class="verifyFieldInArray('branchBank') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="bank.agencyDigit"
            @keydown="$event.key === '-' ? $event.preventDefault() : null"
            @blur="bank.agencyDigit ? rule.verifyNegative(bank.agencyDigit, 'fieldCheckedBankAgencyDigit') : ''"
            @focus="verifyMessageBankAgencyDigit = rule.isFocusVerifyNegative(true, bank.agencyDigit)"
            @change="verifyMessageBankAgencyDigit = rule.isFocusVerifyNegative(false, bank.agencyDigit)"
            id="fieldCheckedBankAgencyDigit"
            :error-messages="verifyMessageBankAgencyDigit ? '*Preencha corretamente' : ''"
            v-mask="'#'"
            label="DV"
            color="textPrimary"
            outlined
            :disabled="isDisabledFields"
            :class="verifyFieldInArray('branchBankCheckDigit') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="bank.account"
            @keydown="$event.key === '-' ? $event.preventDefault() : null"
            @blur="bank.account ? rule.verifyNegative(bank.account, 'fieldCheckedBankAccount') : ''"
            @focus="verifyMessageBankAccount = rule.isFocusVerifyNegative(true, bank.account)"
            @change="verifyMessageBankAccount = rule.isFocusVerifyNegative(false, bank.account)"
            id="fieldCheckedBankAccount"
            v-mask="'#########'"
            :error-messages="verifyMessageBankAccount ? '*Preencha corretamente' : ''"
            label="Nº da Conta"
            placeholder="Informe apenas números"
            color="textPrimary"
            outlined
            :disabled="isDisabledFields"
            :rules="[rule.validateNegativeDecimal(bank.account, 'int')]"
            :class="verifyFieldInArray('bankAccount') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="bank.accountDigit"
            @keydown="$event.key === '-' ? $event.preventDefault() : null"
            @blur="bank.accountDigit ? rule.verifyNegative(bank.accountDigit, 'fieldCheckedBankAccountDigit') : ''"
            @focus="verifyMessageBankAccountDigit = rule.isFocusVerifyNegative(true, bank.accountDigit)"
            @change="verifyMessageBankAccountDigit = rule.isFocusVerifyNegative(false, bank.accountDigit)"
            id="fieldCheckedBankAccountDigit"
            v-mask="'#'"
            label="DV"
            :error-messages="verifyMessageBankAccountDigit ? '*Preencha corretamente' : ''"
            color="textPrimary"
            outlined
            :disabled="isDisabledFields"
            :class="verifyFieldInArray('bankAccountCheckDigit') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Rules from '@/shared/validators/formRules';
import PhysicalPersonService from '@/services-http/contract/PhysicalPersonService';
import BankService from '@/services-http/sdi/BankService';
import ChangedFieldsMixin from '@/shared/mixins/highlightedChangedFields/changedFieldsMixin';

export default {
  name: 'BankInformationComponent',
  data: () => ({
    bank: {
      code: null,
      agency: null,
      agencyDigit: null,
      account: null,
      accountDigit: null,
    },
    banks: [],
    isDisabledFields: true,
    isValidForm: true,
    sessionStorageName: 'beneficiaryBankUpdated',
    verifyMessageBankAgency: false,
    verifyMessageBankAgencyDigit: false,
    verifyMessageBankAccount: false,
    verifyMessageBankAccountDigit: false,
    isFreeMovement: false,
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    isEdit: false,
  }),

  mixins: [
    ChangedFieldsMixin,
  ],

  props: { changedFields: { type: Array } },

  watch: {
    bank: {
      handler(val) {
        if (val.code === '') {
          this.bank.code = null;
        }
        if (val.agency === '') {
          this.bank.agency = null;
        }
        if (val.agencyDigit === '') {
          this.bank.agencyDigit = null;
        }
        if (val.account === '') {
          this.bank.account = null;
        }
        if (val.accountDigit === '') {
          this.bank.accountDigit = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    this.verifyTypeOperationForMovement();
    this.loadBanks();
    await this.loadContractedPlanInfo();
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    } else {
      await this.onClickEdit();
    }
  },

  methods: {
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledBankInformation', this.isDisabledFields);
    },
    onClickSaveSessionStorage() {
      if (!this.$refs.formRegister.validate()) {
        return;
      }

      sessionStorage.setItem(this.sessionStorageName, JSON.stringify({ ...this.bank, code: this.bank.code !== null ? String(this.bank.code) : null }));
      this.isDisabledFields = true;
      this.$emit('disabledBankInformation', this.isDisabledFields);
    },
    async loadContractedPlanInfo() {
      if (sessionStorage.getItem('holderAlteration')) {
        const beneficiaryHolderAlteration = JSON.parse(sessionStorage.getItem('holderAlteration'));
        const bankFinded = beneficiaryHolderAlteration && beneficiaryHolderAlteration.activeBankAccount ? beneficiaryHolderAlteration.activeBankAccount : null;
        this.bank.code = bankFinded && bankFinded.financialBank ? Number(bankFinded.financialBank.code) : null;
        this.bank.agency = bankFinded && bankFinded.agency ? (bankFinded.agency) : null;
        this.bank.agencyDigit = bankFinded && bankFinded.agencyDigit ? (bankFinded.agencyDigit) : null;
        this.bank.account = bankFinded && bankFinded.accountNumber ? (bankFinded.accountNumber) : null;
        this.bank.accountDigit = bankFinded && bankFinded.accountDigit ? (bankFinded.accountDigit) : null;
        setTimeout(() => {
          this.onClickSaveSessionStorage();
        }, 250);
      } else if (sessionStorage.getItem('movementRecord')) {
        await this.mapBeneficiaryMovementRecord();
      }
    },
    async mapBeneficiaryMovementRecord() {
      this.movementRecord = await JSON.parse(sessionStorage.getItem('movementRecord'));
      if (this.movementRecord) {
        this.bank.code = this.movementRecord.bank ? Number(this.movementRecord.bank) : null;
        this.bank.agency = this.movementRecord.branchBank ? (this.movementRecord.branchBank) : null;
        this.bank.agencyDigit = this.movementRecord.branchBankCheckDigit ? (this.movementRecord.branchBankCheckDigit) : null;
        this.bank.account = this.movementRecord.bankAccount ? Number(this.movementRecord.bankAccount) : null;
        this.bank.accountDigit = this.movementRecord.bankAccountCheckDigit ? Number(this.movementRecord.bankAccountCheckDigit) : null;
        setTimeout(() => {
          this.onClickSaveSessionStorage();
        }, 250);
      }
    },
    loadBanks() {
      this.bankService.FindAll().then((response) => {
        if (response && response.data && response.data.content) {
          let banks = response.data.content;
          banks = banks.map((bank) => ({
            id: bank.id,
            description: bank && bank.code ? `${bank.code} - ${bank.name}` : bank.name,
            code: bank.code,
          }));
          this.banks = banks;
        }
      });
    },
  },
  async created() {
    this.rule = new Rules();
    this.physicalPersonService = new PhysicalPersonService();
    this.bankService = new BankService();
  },
};
</script>
