<template>
  <div>
    <v-row class="mt-10 pl-3 pr-7" justify="space-between">
      <div>
        <p class="title">Informações do Titular</p>
      </div>
      <div>
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" color="primary" v-else large @click="onClickSaveSessionStorage()">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-10 pr-4">
        <v-col cols="12" md="6" lg="3">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="holder.financialGroupId"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            placeholder="Grupo Financeiro"
            outlined
            disabled
            color="textPrimary"
            :loading="loadingFinancialGroups"
            :class="verifyFieldInArray('financialGroupId') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">CPF</label>
          <v-text-field
            v-model="holder.document"
            :class="verifyFieldInArray('holderDocumentNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            outlined
            :disabled="isDisabledFields"
            color="textPrimary"
            :rules="holder.document ? [rule.cpf] : []"
            validate-on-blur
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Nome do Titular</label>
          <v-text-field
            v-model.trim="holder.fullname"
            :class="verifyFieldInArray('holderName') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            placeholder="Nome completo"
            maxlength="70"
            outlined
            :disabled="isDisabledFields"
            color="textPrimary"
            :rules="holder.fullname ? [rule.name, rule.validateIsAlphanumeric] : []"
            @blur="holder.fullname = formatter.formatToTitleCase(holder.fullname)"
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Centro de Custo</label>
          <v-sheet
            :class="{
              'd-flex flex-row align-start justify-center rounded-lg transparent mt-2': true,
              'alterationColorText alterationColorBorder': verifyFieldInArray('taxAllocationCode'),
            }"
            outlined>
            <v-row
              no-gutters>
              <v-col
                class="flex-grow-0 flex-shrink-0">
                <v-text-field
                  class="rounded-r-0"
                  v-model.trim="holder.capacityCode"
                  placeholder="Código"
                  style="min-width: 100px;"
                  maxlength="70"
                  outlined
                  color="textPrimary"
                  :disabled="isDisabledFields"
                  :rules="holder.capacityCode ? [rule.required] : []"
                  validate-on-blur
                  @blur="holder.capacityCode = formatter.formatToTitleCase(holder.capacityCode)"
                  @input="['', null, undefined].some(item => item === holder.capacityCode) ? beneficiary.capacityDescription = '' : false"
                />
              </v-col>
              <v-col
                class="flex-grow-1 flex-shrink-1">
                <v-text-field
                  class="rounded-l-0"
                  v-model.trim="holder.capacityDescription"
                  placeholder="Descrição"
                  maxlength="70"
                  outlined
                  color="textPrimary"
                  :disabled="isDisabledFields"
                  :rules="holder.capacityDescription ? [rule.required] : []"
                  validate-on-blur
                  @blur="holder.capacityDescription = formatter.formatToTitleCase(holder.capacityDescription)"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Departamento</label>
          <v-text-field
            v-model.trim="holder.department"
            maxlength="70"
            outlined
            :class="{
              'mt-2': true,
              'alterationColorText alterationColorBorder': verifyFieldInArray('taxAllocationCode'),
            }"
            color="textPrimary"
            placeholder="Informe o Departamento"
            :disabled="isDisabledFields"
            :rules="holder.department ? [rule.required] : []"
            validate-on-blur
            @blur="holder.department = formatter.formatToTitleCase(holder.department)"
          />
        </v-col>
        <template v-if="isFreeMovement">
          <v-col cols="12" md="6" lg="3">
            <label class="label">Matrícula</label>
            <div class="d-flex flex-column">
              <v-text-field
                :class="verifyFieldInArray('registrationPlate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                placeholder="Informe a matrícula"
                v-model="holder.registration"
                maxlength="50"
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <label class="label">Complemento da Matrícula</label>
            <div class="d-flex flex-column">
              <v-text-field
                :class="verifyFieldInArray('registrationComplement') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                placeholder="Informe o complemento"
                v-model="holder.registrationComplement"
                maxlength="50"
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <label class="label">Carteirinha</label>
            <div class="d-flex flex-column">
              <v-text-field
                outlined
                :class="verifyFieldInArray('beneficiaryCard') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :disabled="isDisabledFields"
                v-model="holder.cardNumber"
                v-mask="'###############'"
                min="1"
                placeholder="Informe a carteirinha"
                @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                color="textPrimary"
              />
            </div>
          </v-col>
        </template>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Data de Admissão</label>
          <div class="d-flex flex-column">
            <v-text-field
              :class="verifyFieldInArray('eventDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
              outlined
              v-model="holder.admissionDate"
              :disabled="isDisabledFields"
              append-icon="fas fa-calendar-alt"
              placeholder="DD/MM/YYYY"
              v-mask="'##/##/####'"
              color="textPrimary"
              @input="saveAdmissionDate(holder.admissionDate)"
              :rules="holder.admissionDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.requiredDate] : []"
              validate-on-blur
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import ChangedFieldsMixin from '@/shared/mixins/highlightedChangedFields/changedFieldsMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';

export default {
  name: 'HolderInformation',
  data: () => ({
    valid: true,
    isDisabledFields: true,
    confirmationDialog: { show: false },
    holder: {
      document: null,
      fullname: null,
      admissionDate: null,
    },
    financialGroups: [],
    isFreeMovement: false,
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    isEdit: false,
    loadingFinancialGroups: false,
  }),

  mixins: [
    ChangedFieldsMixin,
    FinancialGroupsMixin,
  ],

  props: { changedFields: { type: Array } },

  async mounted() {
    await this.getFinancialGroups();
    this.verifyTypeOperationForMovement();
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    }
    await this.loadBeneficiary();
    await this.onClickEdit();
    if (!this.isFreeMovement) {
      this.onClickSaveSessionStorage();
    }
    if (sessionStorage.getItem('movementRecord')) {
      this.onClickSaveSessionStorage();
    }
  },

  methods: {
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledHolderInformation', this.isDisabledFields);
    },
    onClickSaveSessionStorage() {
      if (this.$refs.form.validate()) {
        let payload = null;

        if (this.holder) {
          payload = {
            fullname: this.holder.fullname ? this.formatter.formatToTitleCase(this.holder.fullname) : null,
            admissionDate: this.holder.admissionDate ? this.formatter.formatDateBRtoString(this.holder.admissionDate) : null,
            document: this.holder.document ? this.formatter.removeNonDigit(this.holder.document) : null,
            registration: this.holder.registration ? this.holder.registration : null,
            registrationComplement: this.holder.registrationComplement ? this.holder.registrationComplement : null,
            cardNumber: this.holder.cardNumber ? this.holder.cardNumber : null,
            capacityCode: this.holder.capacityCode ? this.holder.capacityCode : null,
            capacityDescription: this.holder.capacityDescription ? this.holder.capacityDescription : null,
            department: this.holder.department ? this.holder.department : null,
          };
        }

        const alterationObject = JSON.parse(sessionStorage.getItem('alterationObject'));
        alterationObject.holder = payload;
        sessionStorage.setItem('alterationObject', JSON.stringify(alterationObject));
        this.isDisabledFields = true;
      }
      this.$emit('disabledHolderInformation', this.isDisabledFields);
    },
    async loadBeneficiary() {
      if (sessionStorage.getItem('holderAlteration')) {
        const beneficiaryHolderAlteration = JSON.parse(sessionStorage.getItem('holderAlteration'));
        const dateFormat = beneficiaryHolderAlteration && beneficiaryHolderAlteration.admissionDate ? this.formatter.formatDate(beneficiaryHolderAlteration.admissionDate) : null;
        this.holder = {
          document: beneficiaryHolderAlteration && beneficiaryHolderAlteration.physicalPersonCPF ? beneficiaryHolderAlteration.physicalPersonCPF : null,
          fullname: beneficiaryHolderAlteration && beneficiaryHolderAlteration.physicalPersonName ? this.formatter.formatToTitleCase(beneficiaryHolderAlteration.physicalPersonName) : null,
          admissionDate: dateFormat || null,
          cardNumber: beneficiaryHolderAlteration.memberCard ? beneficiaryHolderAlteration.memberCard : null,
          capacityCode: beneficiaryHolderAlteration.taxAllocationCode ? beneficiaryHolderAlteration.taxAllocationCode : null,
          capacityDescription: beneficiaryHolderAlteration.taxAllocationDescription ? beneficiaryHolderAlteration.taxAllocationDescription : null,
          department: beneficiaryHolderAlteration.costCenterDepartment ? beneficiaryHolderAlteration.costCenterDepartment : null,
          financialGroupId: beneficiaryHolderAlteration.financialGroupId ? Number(beneficiaryHolderAlteration.financialGroupId) : null,
          financialGroupName: beneficiaryHolderAlteration.financialGroupName ? beneficiaryHolderAlteration.financialGroupName : null,
        };
      } else if (sessionStorage.getItem('dependentAlteration')) {
        const beneficiaryDependentAlteration = JSON.parse(sessionStorage.getItem('dependentAlteration'));
        const dateFormat = beneficiaryDependentAlteration && beneficiaryDependentAlteration.admissionDate ? this.formatter.formatDate(beneficiaryDependentAlteration.admissionDate) : null;
        this.holder = {
          document: beneficiaryDependentAlteration && beneficiaryDependentAlteration.physicalPersonCPF ? beneficiaryDependentAlteration.physicalPersonCPF : null,
          fullname: beneficiaryDependentAlteration && beneficiaryDependentAlteration.physicalPersonName ? this.formatter.formatToTitleCase(beneficiaryDependentAlteration.physicalPersonName) : null,
          admissionDate: dateFormat || null,
          cardNumber: beneficiaryDependentAlteration.memberCard ? beneficiaryDependentAlteration.memberCard : null,
        };
      } else if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = await JSON.parse(sessionStorage.getItem('movementRecord'));
        const dateFormat = this.movementRecord.eventDate ? this.formatter.formatDate(this.movementRecord.eventDate) : null;
        this.holder = {
          document: this.movementRecord.holderDocumentNumber ? this.movementRecord.holderDocumentNumber : null,
          fullname: this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null,
          admissionDate: dateFormat || null,
          registration: this.movementRecord.registrationPlate ? this.movementRecord.registrationPlate : null,
          registrationComplement: this.movementRecord.registrationComplement ? this.movementRecord.registrationComplement : null,
          cardNumber: this.movementRecord.beneficiaryCard ? this.movementRecord.beneficiaryCard : null,
          capacityCode: this.movementRecord.taxAllocationCode ? this.movementRecord.taxAllocationCode : null,
          capacityDescription: this.movementRecord.taxAllocationDescription ? this.movementRecord.taxAllocationDescription : null,
          department: this.movementRecord.costCenterDepartment ? this.movementRecord.costCenterDepartment : null,
          financialGroupId: this.movementRecord.financialGroupId ? Number(this.movementRecord.financialGroupId) : null,
        };
      }
    },
    saveAdmissionDate(admissionDate) {
      if (admissionDate && admissionDate.length === 10) {
        this.$emit('saveAdmissionDate', admissionDate);
      }
    },
  },
  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
  },
};
</script>
